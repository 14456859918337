<template>
  <!-- 引入布局 -->
  <el-container class="home-container">
    <el-header>
      <div>
        <!-- <img src="" alt="" /> -->
      </div>
      <!-- <span>小 wu 的 天 空</span> -->
      <span>A</span>

      <!--用户登陆后展示的头像-->
      <div class="right_box" >
        <el-dropdown  >
          <img src="https://linwu.oss-cn-shanghai.aliyuncs.com/imgs/violet.jpg" alt="">
          <!--头像下拉菜单-->
          <el-dropdown-menu slot="dropdown" >
            <el-dropdown-item icon="el-icon-ship" v-show="hasLogin" @click.native="updateMyData()">修改资料</el-dropdown-item>
            <el-dropdown-item icon="el-icon-switch-button" v-show="hasLogin"   @click.native="logout" >退出登录</el-dropdown-item>
            <el-dropdown-item icon="el-icon-switch-button" v-show="!hasLogin"   >
              <el-link href="/manage/login">立即登陆</el-link>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <!-- 导航菜单上的调控伸缩的按钮 -->
        <div class="toggle-button" @click="toggleCollapase">|||</div>
          <el-menu background-color="#333744" text-color="#eee" active-text-color="#409eff" 
                unique-opened :collapse = "isCollapse" :collapse-transition="false" :router="true" 
                :default-active="activePath">
          <!-- 一级菜单 -->
          <!-- <el-submenu :index = "item.path" v-for="item in menuList " :key="item.id">
            <template slot="title">
              <i :class="item.mainicon" class="icon_font"></i>
              <span>{{item.title}}</span>
            </template>
            -- 二级菜单
            <el-menu-item :index = "it.path" v-for="it in item.sList " 
                          :key="it.id" @click="saveNavActive(it.path)">  
              <template slot="title">
                <i :class="it.subicon" class="icon_font"></i>
                <span>{{it.title}}</span>
              </template>
            </el-menu-item>
          </el-submenu> -->

            <el-menu-item :index = "item.path" v-for="item in menuList " 
                          :key="item.id" @click="saveNavActive(item.path)">  
              <template slot="title">
                <i :class="item.icon" class="icon_font"></i>
                <span>{{item.title}}</span>
              </template>
            </el-menu-item>
        </el-menu>
      </el-aside>
      <!-- 主体 -->
      <el-main class="content-main">
        <router-view></router-view>
      </el-main>
    </el-container>


    <!-- 显示个人资料的对话框 -->
    <!-- :visible.sync用户控制对话框的显示和隐藏 ， addDialogClosed为对话框关闭事件 -->
    <el-dialog title="个人资料" :visible.sync="myDataDialogVisible" width="50%" @close="myDataDialogClosed">
      <!-- :model绑定数据，:rules添加校验规则 -->
      <el-form :model="myDataForm" :rules="myDataRules" ref="myDataFormRef" label-width="70px">
        <!-- 用户名 -->
        <el-form-item label="用户名">
          <el-input v-model="myDataForm.username" disabled></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item label="密码" prop="password">
          <el-input v-model="myDataForm.password"></el-input>
        </el-form-item>
        <!-- 邮箱 -->
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="myDataForm.email"></el-input>
        </el-form-item>
        <!-- 电话 -->
        <el-form-item label="电话" prop="phone">
          <el-input v-model="myDataForm.phone"></el-input>
        </el-form-item>
        <!-- 地址 -->
        <el-form-item label="地址" prop="address">
          <el-input v-model="myDataForm.address"></el-input>
        </el-form-item>
        <!-- 性别 -->
        <el-form-item label="性别">
          <span>{{myDataForm.sex?'男':'女'}}</span>
        </el-form-item>
      </el-form>
      <!-- 表单下方的按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="myDataDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editData">确定</el-button>
      </span>
    </el-dialog>

  </el-container>
</template>


<script>
export default {
  name : 'Home' , 
  data(){
    return{
      menuList: [
        {id:1001,icon:"el-icon-user",title:"仪表盘",path:"/manage/dashboard"},
        {id:1002,icon:"el-icon-user",title:"我的资料",path:"/manage/myData"},
        {id:1003,icon:"el-icon-user",title:"文章",path:"/manage/articles"},
        {id:1004,icon:"el-icon-user",title:"分类和标签",path:"/manage/categoryAndTag"},
        // {id:1005,icon:"el-icon-user",title:"外观",path:"/manage/appearance"},
        // {id:1006,icon:"el-icon-user",title:"统计",path:"/manage/statistics"},
        {id:1007,icon:"el-icon-user",title:"友链",path:"/manage/friendLinks"},
        {id:1008,icon:"el-icon-user",title:"留言和评论",path:"/manage/messageAndComment"},
        {id:1009,icon:"el-icon-user",title:"访客信息",path:"/manage/visitorInfo"},
      ] ,  // 管理员菜单列表，从数据库中查询到，然后填入，并在页面展示
      isCollapse : false ,  // 控制折叠按钮伸缩的属性
      user_id : {id : ""},  // 当前登录用户的id值
      activePath : "/welcome"  , // 默认路径
      myDataForm:{} ,   // 我的资料数据域
      // 显示和隐藏个人资料的对话框
      myDataDialogVisible : false ,
      hasLogin:false , 
      // 修改个人信息的表单验证
      myDataRules:{
        password:[
          {required:true , message:"请输入密码" , trigger:"blur"} , 
          {min:5 , max:10 , message:"长度在 5 到 10 个字符" , trigger:"blur"}
        ] , 
        email:[
          {required:true , message:"请输入邮箱" , trigger:"blur"} , 
        ] , 
        phone:[
          {required:true , message:"请输入电话号码" , trigger:"blur"}
        ] , 
        phone:[
          {required:true , message:"请输入地址" , trigger:"blur"} 
        ] 
      }
    }
  } , 
  methods:{
    // 右上角头像框 退出登录方法
    logout(){
      this.$confirm("确认退出" , "提示" , {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const _this = this ; 
        _this.$axios.get("/logout" , {
          headers:{
            "Authorization":localStorage.getItem("token")
          }
        }).then(res => {
          _this.$store.commit("REMOVE_INFO") ; 
          _this.$router.push("/login") ; 
          this.$message.success("退出成功！") ; 
          
          // window.sessionStorage.clear() ;  // 清除session中保存的登录信息
          this.$router.push("/manage/login") ;    // 回到login页面
        }) 
      }).catch(() => {
        return ; 
      }) ; 
    } , 
    // 将userid发送到后台以获取权限，同时加载菜单，在页面一加载的时候就调用
    // async getMenuList(){
    //   var user_id = sessionStorage.getItem("user_id");   // 获取session中保存的用户id
    //   this.user_id.id = user_id ;   // 将id保存到数据域中
    //   // console.log(this.user_id.id);
    //   const {data:res} = await this.$http.put(`menus?id=${user_id}`) ;   // 通过普通方式将数据发送到后台

    //   // 获取导航菜单(解析json，然后将数据填入上面的menuList数组中)
    //   if(res.flag != 200){
    //     return this.$message.error("获取菜单失败！") ;  // 访问失败的错误提示
    //   }else{
    //     console.log(res);
    //     this.menuList = res.menus ;  // 成功获取数据，将菜单项回填到data数据域中
    //   }
    // } , 
    // 展开或隐藏折叠按钮的方法
    toggleCollapase(){
      this.isCollapse = !this.isCollapse ; 
    } ,
    // 将选中的二级菜单保存到session中的方法
    saveNavActive(activePath){
      // 将path存放在session中
      window.sessionStorage.setItem('activePath' , activePath) ; 
      // 将传入的path保存到数据域中
      // 我觉得不要这句也可以，上面已经把传入的path存到session中了，在页面加载时再拿出来赋值给activePath，所以这里赋值有什么意义？
      // this.activePath = activePath ;   
    } , 

    // 显示个人资料的对话框
    // async myData(){
    //   var user_id = sessionStorage.getItem("user_id");   // 获取session中保存的用户id
    //   const {data : res} = await this.$http.get("getUpdateUser?id=" + user_id) ; 
    //   this.myDataForm = res ; // 将查询到的用户信息反填到编辑用户表单里
    //   this.myDataDialogVisible = true ;  // 开启编辑对话框
    // } , 
    // 关闭修改用户对话框
    myDataDialogClosed(){
      this.$refs.myDataFormRef.resetFields() ;   // 关闭窗口，重置信息
    } , 
    // 确认修改
    editData(){
      this.$refs.myDataFormRef.validate(async valid => {   // 表单验证
        if(!valid) return ;   // 表单验证失败，直接返回
        // 验证成功，发起修改的请求
        const {data:res} = await this.$http.put("editUser" , this.myDataForm)   // 将修改后的信息提交到后台
        if(res != "success"){
          return this.$message.error("操作失败") ; 
        }
        this.$message.success("操作成功") ; 
        // 然后让修改的对话框隐藏
        this.myDataDialogVisible = false ; 
        this.getMenuList() ; 
      }) ; 
    } , 
    updateMyData(){
      this.$message("暂未开放！") ; 
    } ,
    // 检测用户访问的平台给出移动端未适配提示
    browserRedirect() {
  　　var sUserAgent = navigator.userAgent.toLowerCase();
  　　var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
  　　var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
  　　var bIsMidp = sUserAgent.match(/midp/i) == "midp";
  　　var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  　　var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
  　　var bIsAndroid = sUserAgent.match(/android/i) == "android";
  　　var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
  　　var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
  　　if ((bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) ){
        this.$alert('后台管理页面暂未做移动端适配，使用电脑访问可获得更好的浏览体验哦！', '提示', {
          confirmButtonText: '确定'
        });
    　　} else {
          // console.log("电脑");
    　　}　　　　
    }
  } ,
  created(){ 
    // this.postUserId() ; 
    // 查询menulist，当页面加载的时候就调用查询菜单的方法
    // this.getMenuList() ; 
    // 当页面重新加载时，取出我们存放在session中的path，将其赋值给数据域中的activePath，这样选中的path在刷新后就不会丢失了
    // this.activePath = window.sessionStorage.getItem('activePath') ; 

    if (this.$store.state.userInfo) {
      this.hasLogin = true ;  
    }
    this.browserRedirect() ; 
    
  } , 
}
</script>


<style scoped>
/* 布局样式 */
.home-container {
  height: 100vh;
  /* overflow: hidden; */
}
/* .content-main{
  overflow: scroll;
} */

/* 头部样式 */
.el-header {
  background-color: #373d41;
  display: flex;
  justify-content: space-between; /*左右贴边*/
  padding-left: 0;
  color: #eee;
  font-size: 20px;
  align-items: center;
}
.el-header > span {
  margin-left: -75vw;
}
img {
  width: 55px;
  height: 55px;
}
.right_box{
  margin-right: 20px;
  height: 60px;
  width:60px ;
  background-color: #eee;
  cursor: pointer;
  border-radius: 50%;
  padding: 1px;
}

.right_box img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

/* 侧边栏样式 */
.el-aside {
  background-color: #333744;
}
.el-menu {
  border-right: none;
}
.el-menu-item {
  border-right: none;
}
.icon_font{
  font-size: 16px;
  margin-right: 5px;
}
/* 侧边栏上面的伸缩按钮 */
.toggle-button {
  background-color: rgb(18, 135, 244);
  font-size: 14px;
  line-height: 30px;
  color: #eee;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}

/* 主体样式 */
.el-main {
  background-color: #eaedf1;
}

</style>